<template>
	<main>
		<section class="service-area pt-100 pb-50">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center pb-40 ">
						<div class="section-title service-title">
							<h2>Our Services</h2>
							<p>Solutions that fits your budget !!!</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/1.jpg"
									class="img-fluid"
									alt="Dedicated support"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Domain Registration</h5>
								</div>
								<div class="card-text pb-50">
									<p>
										At Extreme Solutions, with an immense domain knowledge, we
										have transformed ourselves into the foremost provider of
										quality, cost effective domain registration services.
									</p>
								</div>
								<a href="javascript:void(0)" @click="$router.push('/domainregistration')" class="btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/2.jpg"
									class="img-fluid"
									alt="Designs & interfaces"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Software Development</h5>
								</div>
								<div class="card-text pb-25">
									<p>
										Extreme Solutions offers a rich repertoire of software
										services to its clients seeking customized software
										solutions. Be it complete office automation or database
										driven solutions, we are the one stop solution centre for
										all your software requirements.
									</p>
								</div>
								<a href="javascript:void(0)" @click="$router.push('/softwaredevelopment')" class="btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/3.jpg"
									class="img-fluid"
									alt="Features & plugins"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Website Development</h5>
								</div>
								<div class="card-text">
									<p>
										At Extreme Solutions, with immense domain knowledge, we have
										transformed ourselves into the foremost provider of quality,
										cost effective web development services including online
										content management, online product catalogues, product
										demos, and other customized offers.
									</p>
								</div>
								<a href="javascript:void(0)" @click="$router.push('/websitedevelopment')" class="btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/4.jpg"
									class="img-fluid"
									alt="Highly customizable"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Web / Email Hosting</h5>
								</div>
								<div class="card-text pb-80">
									<p>
										Extreme Solutions flexible and feature-packed Web hosting
										plans give you everything you need to easily launch your
										website.
									</p>
								</div>
								<a href="javascript:void(0)" @click="$router.push('/webemailhosting')" class="btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/5.jpg"
									class="img-fluid"
									alt="Optimised for speed"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Bulk SMS</h5>
								</div>
								<div class="card-text">
									<p>
										Our cost effective and flexible Bulk SMS plans have helped
										our customers stay connected with their customers or
										potential buyers. We offer various packages for bulk SMS,
										such as, Promotional, Transactional, Opt-In Opt-Out and
										Invitational SMS.
									</p>
								</div>
								<a href="javascript:void(0)" @click="$router.push('/bulksms')" class="btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-xl-4 col-lg-4 col-md-6 mb-50 ">
						<div class="services-box">
							<div class="services-img">
								<img
									src="assets/img/service/6.jpg"
									class="img-fluid"
									alt="Responsive design"
								/>
							</div>
							<div class="services-content">
								<div class="card-title">
									<h5>Digital Marketing</h5>
								</div>
								<div class="card-text pb-55">
									<p>
										Digital marketing refers to advertising delivered through
										digital channels such as search engines, websites, social
										media, email, and mobile apps.
									</p>
								</div>
								<a href="javascript:void(0)" @click="$router.push('/digitalmarketing')" class="btn">Explore</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
  name: 'services'
};
</script>

<style></style>
